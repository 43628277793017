<template>
  <section id="app" class="relative">
    <div class="flex h-screen justify-center items-center" v-if="loading">
      <div>
        <img
          src="/images/splash.png"
          class="h-[30px] md:h-[50px] w-[33px] md:w-[55px] animate-pulse"
          alt="splash screen"
        />
      </div>
    </div>
    <section v-else>
      <header>
        <app-nav @openDrawerNav="openDrawer"></app-nav>
      </header>

      <div v-if="drawer">
        <nav-drawer @close-button="closeDrawer()"></nav-drawer>
      </div>

      <main class="min-h-screen">
        <router-view />
      </main>

      <footer>
        <footer-section></footer-section>
      </footer>

      <section class="fixed z-50 bottom-4 right-4" @click="openDesk">
        <div class="relative cursor-pointer">
          <img
            src="/icons/whatsapp.svg "
            class="w-[50px] h-[50px] 2xl:w-[80px] 2xl:h-[80px]"
            title="Chat with us"
            alt="whatsapp"
          />
        </div>
      </section>

      <section
        class="fixed z-40 right-10 md:right-14 bottom-20 2xl:bottom-32"
        v-if="contactDesk"
      >
        <div class="md:h-[300px] md:w-[400px] bg-white rounded-lg shadow-sm">
          <div class="py-2 px-5 bg-primary-shades rounded-t-lg">
            <div @click="closeDesk" class="flex justify-end cursor-pointer">
              <p class="text-2xl text-white">x</p>
            </div>
            <div class="py-2">
              <h3 class="text-white text-xl 2xl:text-2xl font-bold">HELLO!</h3>
              <p class="text-white">
                Click our contact below to chat on WhatsApp
              </p>
            </div>
          </div>

          <div class="p-10">
            <a
              href="https://wa.me/2347049289252?text=Hello!%20I'm%20texting%20from%20the%20website,%20I%20want%20to%20enquire%20about%20Reddo%20Spaces"
              target="_blank"
              rel="noopener noreferrer"
              class="flex gap-5 items-center p-3 hover:bg-accent-tint rounded-md"
            >
              <span class="w-[50px] h-[50px] rounded-full border">
                <img
                  src="/icons/call-blue.svg"
                  class="rounded-full w-[50px] h-[50px] p-2"
                  alt="help desk"
                />
              </span>
              <span>
                <h4 class="capitalize text-accent-shades text-sm">
                  front office
                </h4>
                <p class="text-xl font-bold">Faith</p>
              </span>
            </a>
          </div>
          <div class="border-b-[0.5px] border-accent-tint"></div>
          <p class="px-10 pt-5">Thanks for contacting Reddo workspace.</p>
        </div>
      </section>
    </section>

    <div
      v-if="showPopup"
      class="fixed inset-0 z-50 p-5 h-full flex justify-center items-center bg-[#333] bg-opacity-70"
    >
      <div>
        <div
          class="card bg w-full md:max-w-[80%] py-10 md:py-0 mb-10 mx-auto h-full md:h-[700px] rounded-lg shadow-lg"
        >
          <div class="md:flex gap-5 h-full">
            <div class="hidden md:flex h-full w-full">
              <img src="/images/45.jpg" class="h-full object-cover" alt="" />
            </div>
            <div class="w-full">
              <div class="flex justify-end items-center m-4">
                <button
                  @click="closePopup"
                  class="px-4 py-2 bg-primary text-white rounded hover:bg-primary-shades"
                >
                  x
                </button>
              </div>
              <div class="px-5 md:px-10 mt-5 md:mt-10 leading-9">
                <p
                  class="text-3xl sm:text-4xl md:text-7xl text-primary font-bold py-5"
                  style="font-family: 'Dancing Script', cursive"
                >
                  Reddo's new Victoria Island workspace is coming soon!
                </p>

                <p
                  class="w-full md:w-[600px] mt-10 text-lg md:text-2xl text-[black] px-5 md:px-10 border-l-2 border-primary-shades"
                >
                  Get ready to experience our brand-new workspace in the heart
                  of Victoria Island, Lagos—designed to inspire creativity and
                  foster community.
                </p>

                <div class="mt-10 md:mt-20">
                  <a
                    href="https://wa.me/2347049289252?text=Hello!%20I'm%20texting%20from%20the%20website,%20I%20want%20to%20enquire%20about%20Reddo%20Spaces"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      class="capitalize btn w-full text-sm md:text-base md:w-[300px] rounded p-2"
                    >
                      Get in touch
                    </button></a
                  >
                </div>

                <div class="flex items-center gap-2 mt-5">
                  <div class="cursor-pointer max-h-[42px] max-w-[42px]">
                    <a
                      href="https://x.com/reddo61127070"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><img
                        src="/icons/twitter.png"
                        class="object-cover hover:scale-125 transition ease-in-out"
                        alt="twitter"
                    /></a>
                  </div>

                  <div class="cursor-pointer max-h-[35px] max-w-[35px]">
                    <a
                      href="https://www.facebook.com/reddoworkspaces"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><img
                        src="/icons/facebook.svg"
                        class="object-cover hover:scale-125 transition ease-in-out"
                        alt="facebook"
                    /></a>
                  </div>

                  <div class="cursor-pointer max-h-[42px] max-w-[42px]">
                    <a
                      href="https://www.youtube.com/@REDDO_WORKSPACE"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><img
                        src="/icons/youtube.svg"
                        class="object-cover hover:scale-125 transition ease-in-out"
                        alt="youtube"
                    /></a>
                  </div>
                  <div class="cursor-pointer max-h-[30px] max-w-[30px]">
                    <a
                      href="https://www.linkedin.com/company/reddo-workspaces/"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><img
                        src="/icons/linkedin.svg"
                        class="object-cover hover:scale-125 transition ease-in-out"
                        alt="linkedin"
                    /></a>
                  </div>
                  <div class="cursor-pointer max-h-[45px] max-w-[45px]">
                    <a
                      href="https://instagram.com/reddo_workspaces"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><img
                        src="/icons/instagram.png"
                        class="object-cover hover:scale-125 transition ease-in-out"
                        alt="instagram"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppNav from "./components/AppNav.vue";
import FooterSection from "./components/FooterSection.vue";
import NavDrawer from "./components/NavDrawer.vue";
export default {
  name: "App",
  components: { FooterSection, AppNav, NavDrawer },

  data() {
    return {
      color: "",
      drawer: false,
      loading: false,
      contactDesk: false,
      showPopup: false,
    };
  },

  created() {
    this.getSiteOpen();

    setTimeout(() => {
      // Check if the pop-up has already been shown
      if (!localStorage.getItem("popupShown")) {
        this.showPopup = true;
      }
    }, 7000);

    // Add event listener to clear localStorage on window close
    window.addEventListener("beforeunload", this.clearPopupState);
  },

  methods: {
    getSiteOpen() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    },

    closePopup() {
      // Set localStorage to indicate the pop-up has been shown
      localStorage.setItem("popupShown", "true");
      this.showPopup = false;
    },

    clearPopupState() {
      localStorage.removeItem("popupShown");
    },

    onScroll() {
      if (document.documentElement.scrollTop > 20) {
        this.color = "#4fdddd";
      } else {
        this.color = "#fff";
      }
    },

    openDrawer() {
      this.drawer = !this.drawer;
    },
    closeDrawer() {
      this.drawer = false;
    },

    openDesk() {
      this.contactDesk = !this.contactDesk;
    },
    closeDesk() {
      this.contactDesk = false;
    },
  },

  // Ensure you remove the event listener when the component is destroyed
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.clearPopupState);
  },
};
</script>

<style scoped>
.bg {
  background: url("/public/images/bg.jpg");
}
</style>
